import DealerOrderTypeCard from "@components/Dealer/DealerOrderTypeCard"
import { DealerOrderCardWrapper, NavListItemBottomWrap, UserManageContentArea } from "@components/Dealer/DealerOrderTypeCard.style"
import { NavListItem } from "@components/Dealer/DealerSignIn.style"
import { UserAccountWrapperContainer } from "@components/GenericBannerContainer"
import { Layout } from "@components/Layout"
import { Checkout } from "@models/Checkout/Checkout"
import { NavListItemContent, NavListItemHeader, UserManageHeader } from "@privateComponents/private.styles"
import { getCurrentCheckout, getEmptyCheckout, saveDealerAddressToLocalStorage, saveIsDealerOrderToLocalStorage, savePaymentTypeToLocalStorage, savePersonalDetailsToLocalStorage } from "@redux/localStorage/checkout"
import { H2 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { graphql, Link, navigate, StaticQuery } from "gatsby"
import React, { Fragment, useState } from "react"
import { Divider, Grid, List } from "semantic-ui-react"
import DealerLoginPage from "./dealer-login"
import { DealerService } from "@services/DealerService"
import { forEach } from "lodash"
import { integer } from "aws-sdk/clients/frauddetector"
import { KpiDeliveryMapping, KpiStatusMapping } from "@utils/Constant"
import moment from "moment"
import DealerList from "@components/Dealer/DealerList"
import DealerNav from "@components/Dealer/DealerNav"
import { Pre } from "aws-amplify-react/lib-esm/AmplifyTheme"

export type CombinationDetailsType = {
  createCombinationDetails: {
    heading: string
    description: string
    link: string
  }
  restyleCombinationDetails: {
    heading: string
    description: string
    link: string
  }
}

export type DealerMyOrderProps = {
  formData: CombinationDetailsType
  apiUrl: string
  checkout: Checkout,
  promotionData: any
}

export type DealerMyOrderStates = {
  loading: boolean
  myOrders: any
  mySummary: any
  myCommission: any
  distributionId: string
}

class DealerMyOrder extends React.Component<DealerMyOrderProps, DealerMyOrderStates>{

  constructor(props: DealerMyOrderProps) {
    super(props);
    this.state = {
        loading: true,
        myOrders: [],
        mySummary: [],
        myCommission: [],
        distributionId: ''
    }
  }

  componentDidMount() {
    const { checkout, apiUrl } = this.props
    const salesPersonId = `${checkout.dealerDetails?.salesPersonId}` || ""
    const distributionId = `${checkout.dealerDetails?.distributionId}` || ""

    DealerService.getDealerOrder(apiUrl, salesPersonId)
    .then(response => {
      if(response.Status === 200){
        this.setState({
          myOrders: response.Data.orders,
          mySummary: response.Data.summaryBox,
          myCommission: response.Data.commissionSummary,
          distributionId: distributionId
        })
      } else {
        const emptyCheckout = getEmptyCheckout()
        savePersonalDetailsToLocalStorage(emptyCheckout)
        saveIsDealerOrderToLocalStorage(emptyCheckout.isDealerOrder)
        saveDealerAddressToLocalStorage(emptyCheckout.dealerAddress)
        savePaymentTypeToLocalStorage(emptyCheckout.paymentType)
        navigate("/dealer-login")
      }
    })
    .catch(exception => {
      const emptyCheckout = getEmptyCheckout()
      savePersonalDetailsToLocalStorage(emptyCheckout)
      saveIsDealerOrderToLocalStorage(emptyCheckout.isDealerOrder)
      saveDealerAddressToLocalStorage(emptyCheckout.dealerAddress)
      savePaymentTypeToLocalStorage(emptyCheckout.paymentType)
      navigate("/dealer-login")
    })
  }

  render() {
  const { formData, checkout, apiUrl, promotionData } = this.props
  const dealerName = checkout.dealerDetails?.dealerName
  const salesPersonEmail = checkout.dealerDetails?.salesPersonEmail.split(' ')[0] || ""

  var { myOrders, mySummary, myCommission } = this.state

  var distributionId =  parseInt(this.state.distributionId)

  const dealerOptions = [
    {
      key: dealerName,
      text: dealerName,
      value: dealerName,
      selected: true
    }
  ]

  var prevTitle = myCommission.PreviousRunFinalised > 0 ? "Previous Run (Finalised)" : "Previous Run (To be finalised)"
  var prevAmount = myCommission.PreviousRunFinalised > 0 ? myCommission.PreviousRunFinalised : myCommission.PreviousRunNotFinalised

  return (

    <Layout version="simple">
      <UserAccountWrapperContainer bgLeftColor={theme.brand.colors.blue}
      mobilePaddingBottom={0}
      mobilePaddingTop={0}>
          <Grid>
              <Grid.Row>
                  <Grid.Column only="computer"
                  computer={4}
                  style={{
                      float: "right",
                      backgroundColor: theme.brand.colors.lightToGrey,
                      minHeight: "calc(100vh - 180px)",
                      paddingTop: "70px",
                    }}
                  >
                  <List selection verticalAlign="middle" size="huge">
                      <DealerList dealerOptions={dealerOptions} apiUrl={apiUrl} salesPersonEmail={salesPersonEmail}/>
                      <Divider />
                      <DealerNav activeCss={['', 'item-active', '']}/>
                  </List>
                  </Grid.Column>
                  <UserManageContentArea mobile={16} tablet={16} computer={12}>
                      <UserManageHeader>
                      <Grid stackable>
                          <Grid.Column computer={10} tablet={10} mobile={16}>
                          <H2>My Orders</H2>
                          </Grid.Column>
                          <Grid.Column
                          computer={6}
                          tablet={6}
                          mobile={16}
                          textAlign="right"
                          >
                          </Grid.Column>
                      </Grid>
                      </UserManageHeader>
                      <br/>
                      <DealerOrderCardWrapper>
                        <Fragment>
                          <h2>Sales</h2>
                          <Grid stretched style={{margin: '0px 0px 50px 0px'}}>
                            <table className="ui celled padded table">
                              <thead>
                                <tr>
                                  <th>My Sales This Month</th>
                                  <th>My Sales Total (last 6 Months) </th>
                                  <th>Number of Plates Sold (last 6 Months)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    ${mySummary.ThisMonthSale}
                                  </td>
                                  <td className="single line">
                                    ${mySummary.MyCurrentYearSale}
                                  </td>
                                  <td>
                                     {mySummary.NumberOfPlates}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                        </Fragment>
                        {(distributionId != 4 && distributionId != 1) && <Fragment>
                          <h2>Commissions</h2>
                          <Grid stretched style={{margin: '0px 0px 50px 0px'}}>
                            <table className="ui celled padded table">
                              <thead>
                                <tr>
                                  <th>{prevTitle}
                                    <span style={{display: 'block',fontSize: '12px',fontWeight: '100',fontStyle: 'italic'}}>{myCommission.PrevRunFrom} - {myCommission.PrevRunTo}</span>
                                  </th>
                                  <th>This Run (To be finalised)
                                    <span style={{display: 'block',fontSize: '12px',fontWeight: '100',fontStyle: 'italic'}}>{myCommission.ThisRunFrom} - {myCommission.ThisRunTo}</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="single line">
                                    ${prevAmount}
                                  </td>
                                  <td className="single line">
                                    ${myCommission.CurrentRun}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                        </Fragment>}
                        <h2>Order History</h2>
                        <Grid stretched style={{margin: '0px 0px 50px 0px'}}>
                        <table className="ui celled padded table">
                          <thead>
                            <tr><th className="single line">Order Date</th>
                            <th>Order Number (ID)</th>
                            <th>Combination</th>
                            <th>Order Value</th>
                            <th>Owners Name</th>
                            <th>Order Status</th>
                            <th>Manufacture Status</th>
                            {(distributionId != 4 && distributionId != 1) && <th>Commission Payable</th>}
                          </tr></thead>
                          <tbody>
                            {myOrders.map((order: any,i: integer)=>{
                            var manStat = KpiDeliveryMapping[(order.DeliveryType).replace( /\s/g, '')]
                            var kpiStat = KpiStatusMapping[(order.KPIStatus).replace( /\s/g, '')]
                            var overManStat = kpiStat === "Order Completed" || kpiStat === "Downloaded to Licensys" ? manStat : ""
                            return (
                            <tr>
                              <td>
                                {moment(order.CreatedOn).format("DD/MM/YYYY")}
                              </td>
                              <td className="single line">
                                {order.TransactionId}
                              </td>
                              <td>
                              {order.Combination}
                              </td>
                              <td>
                              ${order.AmountIncGST}
                              </td>
                              <td>
                                {order.FirstName} {order.LastName}
                              </td>
                              <td>
                              {kpiStat}
                              </td>
                              <td>
                              {overManStat}
                              </td>
                              {(distributionId != 4 && distributionId != 1) && <td>
                              ${kpiStat != "Cancelled" ? order.SalespersonCommission : 0}
                              </td>}
                            </tr>)})}
                          </tbody>
                          {/* <tfoot>
                            <tr><th colSpan={8}>
                              <div className="ui right floated pagination menu">
                                <a className="icon item">
                                  <i className="left chevron icon"></i>
                                </a>
                                <a className="item">1</a>
                                <a className="item">2</a>
                                <a className="item">3</a>
                                <a className="item">4</a>
                                <a className="icon item">
                                  <i className="right chevron icon"></i>
                                </a>
                              </div>
                            </th>
                          </tr></tfoot> */}
                        </table>
                        </Grid>
                      </DealerOrderCardWrapper>
                  </UserManageContentArea>
              </Grid.Row>
          </Grid>
      </UserAccountWrapperContainer>
    </Layout>
  )
}}

const DealerMyOrderPage = (props: any) => {
  const checkout= getCurrentCheckout()
  return (
    checkout.isDealerOrder ?
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              apiUrl
            }
          }
          kontentItemDealerNewOrder {
            elements {
              new_description {
                value
              }
              restyle_heading {
                value
              }
              restyle_description {
                value
              }
              new_heading {
                value
              }
            }
          }
          allKontentItemRichHtmlBlock(filter: {system: {name: {eq: "Dealer Promotion Page"}}}) {
            nodes {
              elements {
                title {
                  value
                }
                content {
                  value
                }
                banner {
                  value
                }
              }
              system {
                codename
                name
              }
            }
          }
        }
      `}
      render={data => {
        const { elements } = data.kontentItemDealerNewOrder
        const formData: CombinationDetailsType = {
          createCombinationDetails: {
            heading: elements.new_heading.value,
            description: elements.new_description.value,
            link: "/create"
          },
          restyleCombinationDetails: {
            heading: elements.restyle_heading.value,
            description: elements.restyle_description.value,
            link: "/restyle"
          },
        }
        const checkout = getCurrentCheckout()
        const apiUrl = data.site.siteMetadata.apiUrl
        const promotionData = data.allKontentItemRichHtmlBlock.nodes[0].elements
        return <DealerMyOrder checkout={checkout} formData={formData} apiUrl={apiUrl} promotionData={promotionData}/>
      }}
    ></StaticQuery>
    :
    <DealerLoginPage />
  )
}

export default DealerMyOrderPage