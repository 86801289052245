import Auth from "@aws-amplify/auth"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import KpButton from "@elements/KpButton/KpButton"
import { NewListingTitle } from "@privateComponents/private.styles"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import theme from "@styles/theme"
import {
  ListingFormDataType,
  EditListingProps,
  ListingValidationErrorType,
  initListingValidationErrorValue,
} from "@utils/ListingTypes"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
import { Grid } from "semantic-ui-react"
import EditListingForm from "./EditListingForm"

type Props = {
  editProps: EditListingProps
  listingId: number
}

const EditListing = (props: Props) => {
  const { formData, apiUrl } = props.editProps
  const listingId = props.listingId
  const [listingInfo, setListingInfo] = React.useState<ListingFormDataType>({
    combination: "",
    description: "",
    plateDesignName: formData.plateDesigns[0].value,
    condition: formData.plateConditions[0].value,
    isFeatured: false,
    isManufactured: formData.isManufactured[0].value == "true" ? true : false,
    price: 0,
    imageUrls: [],
    listingStatus: "",
  })
  const [errors, setErrors] = React.useState<ListingValidationErrorType>(
    initListingValidationErrorValue
  )
  useEffect(() => {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      MarketplaceListingService.getListingDetailsByUser(apiUrl, listingId, jwt)
      .then(data => {

        if (data != null){
          setListingInfo({
            ...data,
            imageUrls: data.imageUrls ? JSON.parse(data.imageUrls) : [],
          })  
        } else {
          navigate("/404/");
        }             
      })
      .catch(err => {
        console.log("error on loading listing id.")
        //TODO: UI proper handle error info
      })
    })
  }, [])

  const goBack = () => {
    navigate("/app/listings")
  }
  const submitListing = () => {
    if (listingInfo.price <= 0 || errors.askingPrice.length > 0 || errors.description.length > 0){
      return
    }
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      MarketplaceListingService.editListing(
        apiUrl,
        jwt,
        {
          combination: listingInfo.combination,
          condition: listingInfo.condition
            ? listingInfo.condition[0].toUpperCase() +
              listingInfo.condition.slice(1)
            : "",
          description: listingInfo.description,
          isFeatured: listingInfo.isFeatured,
          plateDesignName: listingInfo.plateDesignName,
          price: listingInfo.price,
          isManufactured: listingInfo.isManufactured,
          imageUrls: JSON.stringify(listingInfo.imageUrls),
          listingStatus: listingInfo.listingStatus,
        },
        listingId
      ).then((data: any) => {
        if (data != null && data != undefined) {
          navigate("/app/listings")
        }
      })
    })
  }

  return (
    <GenericBannerContainer
      padding={{
        mobile: {
          top: 20,
          bottom: 40,
        },
        desktop: {
          top: 40,
          bottom: 60,
        },
      }}
      backgroundColor={theme.brand.colors.beige}
    >
      <Grid stackable>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <NewListingTitle>Update Marketplace Listing</NewListingTitle>
          <EditListingForm
            formInfo={formData}
            listingInfo={listingInfo}
            setListingInfo={setListingInfo}
            errors={errors}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <Grid reversed="mobile vertically">
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <KpButton
                id="pms-back"
                fullWidth="mobile"
                buttonType="secondary"
                color={theme.brand.colors.black}
                onClick={goBack}
              >
                BACK
              </KpButton>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
              <KpButton
                id="pms-next"
                fullWidth="mobile"
                buttonType="primary"
                color={theme.brand.colors.blue}
                onClick={submitListing}
              >
                Update Listing
              </KpButton>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </GenericBannerContainer>
  )
}

export default EditListing
