import React, { Props } from "react"
import { graphql, navigate } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from "@components/SEO"
import { SignupBannerWrapper } from "@components/SignupBanner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { useEffect } from "react"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { ListingDetailsBanner } from "@components/ListingDetailsBanner"
import { Grid, Responsive } from "semantic-ui-react"
import theme from "@styles/theme"
import StandardListingItemCard from "@components/ListingItemCard/StandardListingItemCard"
import { H2 } from "@styles/Global.styles"
import { ListingDetailsType, ListingShortType } from "@utils/ListingTypes"
import { buildDefaultImageUrl } from "@utils/Helpers"
import { Auth } from "aws-amplify"

const queryString = require('query-string');

const ListingDetailPage = (props: any) => {
  const apiUrl = props.data.site.siteMetadata.apiUrl;
  const query = queryString.parse(props.location.search);
  const [listingDetails, setListingDetails] = React.useState<ListingDetailsType>()
  const [relatedListings, setRelatedListings] = React.useState<ListingDetailsType[]>([]);
  const [error, setError] = React.useState({})

  useEffect(() => {
    var listingId = parseInt(query.listingId);
    if(listingId > 0) {
        MarketplaceListingService.getListingDetails(apiUrl, query.listingId).then((data: any) => {
          if (data != null){
            setListingDetails({
              ...data,
              imageUrls: data.imageUrls
                ? [
                  buildDefaultImageUrl(apiUrl, data.combination),
                  ...JSON.parse(data.imageUrls),
                ]
                : [buildDefaultImageUrl(apiUrl, data.combination)],
            })
            MarketplaceListingService.getRelatedListings(apiUrl, query.listingId, 3).then((data: any) => {
              setRelatedListings(data);
            });
          } else {
            navigate("/404/");
          }      
        
        }).catch((error: any) => {

          //TODO: redirect to listing not found page
          setError("Server error. Please try again. Failed to load data");
        })
      
    }
  }, [props.location])

  const { elements } = props.data.kontentItemListingItemPage;
  const seoData = {
    ...elements,
    url: {
      value: props.location.pathname + props.location.search
    }
  };
  const seo = mapToSEO(seoData);
  const kiwiVipVisible = elements.kiwivip_signup.value[0].codename;
  const kiwiVipVersion = elements.kiwivip_signup_version.value[0].codename;
  return (<Layout location={props.location} version="simple" authEnabled={true}>
    <SEO {...seo} />
    {
      listingDetails !== undefined &&
      <ListingDetailsBanner listing={listingDetails} apiUrl={apiUrl} />
    }
    <ResponsiveRelatedListingContainer key={props.location.search} relatedListings={relatedListings} />
    {kiwiVipVisible == "visible" && (
      <SignupBannerWrapper version={kiwiVipVersion} />
    )}
  </Layout>
  )
}
type RelatedListingsProps = {
  relatedListings: ListingDetailsType[]
}
const ResponsiveRelatedListingContainer = (props: RelatedListingsProps) => {
  const relatedListings = props.relatedListings
  return (
    <GenericBannerContainer
      padding={{
        mobile: {
          top: 80,
          bottom: 80,
        },
        desktop: {
          top: 100,
          bottom: 100,
        },
      }}
      backgroundColor={theme.brand.colors.alterGrey}
    >
      <H2 textAlign="center">You may also be interested in ...</H2>
      <Responsive {...Responsive.onlyComputer}>
        <Grid columns={3}>
          {
            relatedListings.map(item => {
              return (
                <Grid.Column>
                  <StandardListingItemCard {...item} />
                </Grid.Column>
              );
            })
          }
        </Grid>
      </Responsive>

      <Responsive {...Responsive.onlyTablet}>
        <Grid columns={2}>
          {
            relatedListings.slice(0, 2).map(item => {
              return (
                <Grid.Column>
                  <StandardListingItemCard {...item} />
                </Grid.Column>
              );
            })
          }
        </Grid>
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <Grid columns={1}>
          {
            relatedListings.map(item => {
              return (
                <Grid.Column>
                  <StandardListingItemCard {...item} />
                </Grid.Column>
              );
            })
          }
        </Grid>
      </Responsive>
    </GenericBannerContainer>
  )
}
export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemListingItemPage {
      elements {
        kiwivip_signup {
          value {
            codename
          }
        }
        seo__noindex {
          value {
            codename
          }
        }
        seo__page_description {
          value
        }
        seo__nofollow {
          value {
            codename
          }
        }
        title {
          value
        }
        seo__page_title {
          value
        }
        kiwivip_signup_version {
          value {
            codename
          }
        }
      }
    }
  }
`
export default ListingDetailPage
